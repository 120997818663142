html {
	font-size: 14px;
	font-weight: bold;
}

body {
	/* background-color: #d5d5d5; */
	background-color: #d4d8df;
}

.navbar {
	background-color: #26456e !important;
}

.navbar-dark .navbar-nav .nav-link {
	color: #ffffff;
	margin-right: 10px;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
	border-bottom: 1px solid #ffffff;
	color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link:active {
	border-bottom: 1px solid #ffffff;
	color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link:target {
	border-bottom: 1px solid #ffffff;
	color: #ffffff;
}

label {
	font-weight: 600;
	color: #8d919e;
}

input[type='date'] + label,
input[type='datetime-local'] + label,
select + label {
	color: #1f2024;
}

input[type='text'],
input[type='password'],
input[type='number'],
input[type='date'],
input[type='datetime-local'] {
	font-weight: 600;
}

a,
.btn-link {
	text-decoration: none;
	vertical-align: middle;
	color: #26456e;
}

.btn-link {
	padding: 0;
	margin: 0;
}

.not-set {
	/* color: #c5c5c5; */
	color: #868ea1;
	font-style: italic;
}

.card {
	border-color: #6c757d;
}

.card-header {
	color: #ffffff;
	background-color: #26456e;
	/* background-color: #335374; */
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	background-color: #26456e;
}

.nav-tabs .nav-link {
	font-size: 1.2rem;
	padding: 15px 0px;
}

.nav-fill {
	background: #edf5ff;
}

.accordion-button {
	font-size: 1.2rem;
}

.row-styled {
	/* background-color: #d4d8df; */
	/* background-color: #eeeeee; */
	padding-top: 20px;
	border-radius: 5px;
	margin-bottom: 20px;
}

form {
	width: 60%;
	margin-left: auto;
	margin-right: auto;
}

.vertical-center {
	min-height: 80%; /* Fallback for browsers do NOT support vh unit */
	min-height: 80vh; /* These two lines are counted as one :-)       */

	display: flex;
	align-items: center;
}

.client-page-cards {
	border: 0;
	border-radius: 10px;
	margin-bottom: 20px;
}

#dashboard-events-table td {
	font-weight: 600;
}

td {
	vertical-align: middle;
}

/* .mobile-dashboard-events-view {
	font-size: 0.9rem;
} */

#dashboard-events-filter-table {
	text-align: center;
	margin: 0 auto 20px;
	font-weight: bold;
	width: 60%;
	cursor: pointer;
}

#dashboard-events-filter-table td {
	width: 14.2%;
}

.copy {
	cursor: copy;
}

#dashboard-events-filter-list {
	text-align: center;
	margin: 0 auto;
	font-weight: bold;
	width: 50%;
	cursor: pointer;
	font-size: 0.9rem;
}

.individual {
	background: #dc3545;
}

.master {
	background: #0d6efd;
}

.criminal {
	background: #dc35cf;
}

.individual,
.master,
.criminal {
	color: #ffffff;
	padding: 1px 3px;
	border-radius: 5px;
}
